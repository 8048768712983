import { FC } from "react";

import Image from "next/image";
import locationLogo from "@/img/location.svg";

interface LocationInputProps {
  onClick: () => void;
  location: string;
  setZipcode: (newZipcode: string) => void;
  zipcode: string;
}

const LocationInput: FC<LocationInputProps> = ({
  onClick,
  location,
  zipcode,
  setZipcode,
}) => {
  return (
    <div className="mb-2">
      <i className="absolute p-3 pl-4">
        <Image src={locationLogo} alt="" />
      </i>
      <button
        className={`${
          location ? "text-black" : "text-gray-701"
        } hover:outline-slate-600 h-12 w-full max-w-md overflow-clip rounded-md border border-gray-400 bg-transparent pl-12 text-left focus:outline-blue`}
        value={zipcode ? zipcode : location}
        name="location"
        placeholder="Add City, State, or Zip Code"
        onClick={onClick}
        type="button"
      >
        {location ? location : "Add City, State, or Zip Code"}
      </button>
    </div>
  );
};

export default LocationInput;
