import Image from "next/image";
import locationWhite from "@/img/location_white.svg";
import LocationModal from "../location/LocationModal";
import magnifyingGlassWhite from "@/img/magnifying_glass_white.svg";

import { FC, MutableRefObject } from "react";

interface TrySearchProps {
  setSearchQuery?: (newSearchQuery: string) => void;
  searchQuery: string;
  zipcode: string;
  setZipcode?: (newZipcode: string) => void;
  handleLocationInputClick: () => void;
  handleSubmit: (e, modalZipcode?) => void;
}

const TrySearch: FC<TrySearchProps> = ({
  searchQuery,
  setSearchQuery,
  zipcode,
  setZipcode,
  handleLocationInputClick,
  handleSubmit,
}) => {
  return (
    <div className="align-center grid bg-youdleDarkBlue py-20 md:grid-cols-2 md:items-center md:justify-between xl:px-32">
      <div className="justify-start">
        <div className="mt-4 flex w-full flex-col items-center justify-center md:w-fit">
          {/* Search Bar */}
          <div className="flex w-full flex-col items-center justify-center pt-2 md:w-fit md:pt-0">
            <form
              className="flex w-max flex-col items-center sm:flex-row "
              id="form"
              role="search"
              onSubmit={(e) => {
                handleSubmit(e, zipcode);
              }}
            >
              {/* {% csrf_token %} */}
              <div className="container h-12 w-[87vw] rounded bg-blue-600 md:inline md:w-max">
                <input
                  type="search"
                  className="w-2/3 rounded bg-blue-600 py-3 pl-4 text-base hover:bg-blue-500 focus:bg-blue-600  focus:outline-blue-500 sm:mb-2 md:w-7/12"
                  id="query"
                  name="searched"
                  placeholder="Search Product"
                  minLength={3}
                  pattern=".{3,}"
                  onInvalid={(e) =>
                    e.currentTarget.setCustomValidity(
                      "Your search query must be at least 3 characters long.",
                    )
                  }
                  required
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    console.log(e.target.value);
                    console.log(searchQuery);
                    e.currentTarget.setCustomValidity("");
                  }}
                />

                <div className="inline hover:bg-blue-500">
                  <i className="absolute ml-[-.5rem] py-4">
                    <Image src={locationWhite} alt="" />
                  </i>
                  <input
                    type="search"
                    className="w-1/3 overflow-visible rounded bg-blue-600 py-3 pl-4 pr-4 text-base hover:bg-blue-500 focus:bg-blue-600 focus:outline-blue-500 sm:mb-0"
                    id="zipcode"
                    name="zipcode"
                    placeholder="Location"
                    pattern="(^\d{5}$)|(^\d{5}-\d{4}$)"
                    minLength={5}
                    maxLength={5}
                    onInvalid={(e) =>
                      e.currentTarget.setCustomValidity(
                        "Your zip code must be exactly 5 characters long.",
                      )
                    }
                    required
                    value={zipcode}
                    onClick={handleLocationInputClick}
                    onChange={(e) => {
                      console.log(zipcode);
                      setZipcode(e.target.value);
                      e.currentTarget.setCustomValidity("");
                    }}
                  />
                </div>
                <button
                  type="submit"
                  id="submit"
                  onSubmit={(e) => {
                    handleSubmit(e, zipcode);
                  }}
                  //   onClick={handleSubmit}
                  className="absolute mt-2 hidden w-10 rounded bg-red p-2 pr-1 hover:bg-red-500 md:ml-[-.75rem] md:mt-[5px] md:inline"
                >
                  <a>
                    <Image src={magnifyingGlassWhite} alt="" />
                  </a>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=" md:max-w-lg">
        <p className="pt-8 text-center text-4xl  font-bold text-white md:pt-0 md:text-start">
          Try the Youdle search
        </p>
      </div>
    </div>
  );
};

export default TrySearch;
