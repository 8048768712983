import { FC, useEffect, useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { GoogleGeocodeLocation, libraries } from "utils/googleMapsUtils";
import Navbar from "@/components/mainComponents/Navbar";
import YoudleNews from "@/components/mainComponents/YoudleNews";
import SearchPane from "@/components/mainComponents/SearchPane";
import Reviews from "@/components/mainComponents/Reviews";
import CantFind from "@/components/mainComponents/CantFind";
import TrySearch from "@/components/mainComponents/TrySearch";
import YoudleAds from "@/components/mainComponents/YoudleAds";
import Footer from "@/components/mainComponents/Footer";
import LocationModal from "@/components/location/LocationModal";
import AdBanner from "@/components/mainComponents/AdBanner";
import { useRouter } from "next/router";
import Head from "next/head";
import YoudleModal from "@/components/modals/YoudleModal";
import MailchimpModal from "@/components/modals/MailchimpModal";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const Landing: FC = () => {
  const [zipcode, setZipcode] = useState("");
  const [location, setLocation] = useState("");
  const [geocodeLocation, setGeocodeLocation] =
    useState<GoogleGeocodeLocation>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const router = useRouter();
  const [searchQueryError, setSearchQueryError] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [mailOpen, setMailOpen] = useState(false);

  useEffect(() => {
    delay(1500).then(() => setMailOpen(true));
  }, []);

  const handleSubmit = (e, curZipcode = zipcode) => {
    if (e) {
      e.preventDefault();
    }
    if (searchQuery.length < 3) {
      setSearchQueryError(
        "Your search query must be at least 3 characters long.",
      );
    } else {
      setSearchQueryError("");
    }

    if (curZipcode.length !== 5) {
      setZipcodeError("Your zip code must be exactly 5 characters long.");
    } else {
      setZipcodeError("");
    }

    if (searchQuery.length < 3 || curZipcode.length !== 5) {
      // Display an error message or perform any desired action
      console.log("ERROR");
      return;
    }
    router.push(`/loading?searchQuery=${searchQuery}&zipcode=${curZipcode}`);
  };

  // Navbar Zipcode Modal
  const [open, setOpen] = useState(false);
  const handleLocationInputClick = () => {
    console.log("Searchpane test");
    setOpen(true);
  };
  const openRef = useRef(open);
  useEffect(() => {
    openRef.current = open;
    console.log(openRef.current);
  }, [open]);

  const zipcodeRef = useRef(zipcode);
  useEffect(() => {
    zipcodeRef.current = zipcode;
    console.log(zipcodeRef.current);
  }, [zipcode]);

  const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });

  const props = {
    navbar: {
      handleLocationInputClick,
      zipcode,
      setZipcode,
      handleSubmit,
    },
    searchPane: {
      zipcode,
      setZipcode,
      searchQuery,
      setSearchQuery,
      location,
      handleLocationInputClick,
      handleSubmit,
      searchQueryError,
      zipcodeError,
    },
    locationModal: {
      open,
      setOpen,
      openRef,
      zipcode,
      setZipcode,
      isLoaded,
      apiKey,
      setLocation,
      geocodeLocation,
      setGeocodeLocation,
      handleSubmit,
    },
    trySearch: {
      searchQuery,
      setSearchQuery,
      zipcode,
      setZipcode,
      handleSubmit,
      handleLocationInputClick,
    },
  };

  return (
    <div className="bg-white">
      <Head>
        <title> In-Stock Product Locator | YoudleIt.com </title>
        <meta
          name="description"
          content={`Find in-stock products near you with YoudleIt's homepage, your go-to product locator. Discover real-time availability in your area.`}
        />
        <meta
          name="keywords"
          content="In-stock products, Product locator, Real-time availability, Find nearby products, Near me"
        />

        <meta
          property="og:title"
          content="In-Stock Product Locator | YoudleIt.com "
        />
        <meta
          property="og:description"
          content={`Find in-stock products near you with YoudleIt's homepage, your go-to product locator. Discover real-time availability in your area.`}
        />
      </Head>
      {/* <YoudleModal open={true} setOpen={() => null} title={"test"}>
        Hi
      </YoudleModal> */}

      <MailchimpModal open={mailOpen} setOpen={setMailOpen} />
      <Navbar {...props.navbar} isLanding={true} />
      <SearchPane {...props.searchPane} />
      {open && <LocationModal {...props.locationModal} />}
      <AdBanner />
      <YoudleNews />
      <Reviews />
      <CantFind />
      <TrySearch {...props.trySearch} />
      {/* <YoudleAds /> */}
      <Footer />
    </div>
  );
};

export default Landing;
