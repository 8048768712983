import type { PostgrestError } from "@supabase/supabase-js";
import { supabaseClient, insertData } from "./supabaseUtils.mjs";
import { on } from "events";

export const TRENDING_PRODUCTS_COUNT = 5;
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const YESTERDAY_DATE_STRING = yesterday.toISOString().split("T")[0]; // format as YYYY-MM-DD

type ProductData = {
  product_id: number;
  product_name: string;
  upc: string;
  product_image: {
    image_link: string;
  }[];
};

type ProductDataCount = {
  product: ProductData;
  count: number;
};

type DatabaseUpdateResponse = {
  data: {
    search_id: number;
    date: string;
    product: ProductData[];
  };
  error: PostgrestError;
};

type DatabaseGetResponse = {
  data: {
    product_name: string;
    image_link: string;
    upc: string;
    trending_date;
  }[];
  error: PostgrestError;
};

export type TrendingProduct = {
  upc: string;
  product_name: string;
  image_link: string;
  trending_date: string;
};

export const updateTrendingProducts = async () => {
  console.log('-------> Updating trending products...');
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const oneWeekAgoString = oneWeekAgo.toISOString().split("T")[0]; // format as YYYY-MM-DD
  console.log('-------> One week ago:', oneWeekAgoString);

  const searchLogs = await getSearchLogsGteGate(oneWeekAgoString);
  console.log('-------> Retrieved search logs...:');
  console.log(searchLogs);
  const productCount = countProducts(searchLogs);
  const sorted = sortProducts(productCount);
  const trendingProducts = createTrendingProducts(sorted);
  try {
    await insertData("trending_products", trendingProducts);
    console.log(trendingProducts);
  } catch (e) {
    console.log(e);
  }

  async function getSearchLogsGteGate(oneWeekAgo: string) {
    const { data, error } = (await supabaseClient
      .from("search_log")
      .select("search_id, date, product (product_id, product_name, upc, product_image (image_link))")
      .gte("date", oneWeekAgo)) as DatabaseUpdateResponse;
    console.log(data)
    if (error) {
      console.log(error);
      throw error;
    }
    return data;
  }

  function countProducts(searchLogs: DatabaseUpdateResponse["data"]) {
    const productCount = {};
    for (let i = 0; i < Object.keys(searchLogs).length; i++) {
      const search_log = searchLogs[i];
      for (let j = 0; j < Object.keys(search_log.product).length; j++) {
        const product: ProductData = search_log.product[j];
        // If the product doesn't have a upc, skip it
        if (!product.upc) {
          continue;
        }
        if (productCount[product.upc]) {
          productCount[product.upc].count += 1;
        } else {
          productCount[product.upc] = { product, count: 1 };
        }
      }
    }
    return productCount as ProductDataCount;
  }

  function sortProducts(productCount: ProductDataCount) {
    const sorted = [];
    for (const upc of Object.keys(productCount)) {
      sorted.push(productCount[upc]);
    }
    sorted.sort((a, b) => {
      return b.count - a.count;
    });
    return sorted as ProductDataCount[];
  }

  function createTrendingProducts(sorted: ProductDataCount[]) {
    const trendingProducts: TrendingProduct[] = [];

    for (let i = 0; i < TRENDING_PRODUCTS_COUNT; i++) {
      const product = sorted[i].product;
      console.log(sorted[i]);
      console.log(product);
      const imageIndex = getImageIndex(product.product_image);
      if (imageIndex === -1) {
        // If the product doesn't have an image or if
        // its a low-quality Kroger image, skip it
        sorted.splice(i, 1);
        i--;
        continue;
      }
      const trendingProduct: TrendingProduct = {
        upc: product.upc,
        product_name: product.product_name,
        image_link: product.product_image[imageIndex].image_link,
        trending_date: YESTERDAY_DATE_STRING,
      };
      trendingProducts.push(trendingProduct);
    }
    return trendingProducts;

    // If the product is from Kroger, get the best image available.
    function getImageIndex(product_image: ProductData["product_image"]) {
      let imageIndex = -1;
      if (product_image.length === 0) {
        return imageIndex;
      }
      if (product_image[0].image_link.includes("www.kroger.com")) {
        product_image.forEach((image, index) => {
          if (image.image_link.includes("medium")) {
            imageIndex = index;
          }
          else if (image.image_link.includes("large")) {
            imageIndex = index;
            // eslint-disable-next-line no-useless-return
            return;
          }
        });
      }
      else {
        imageIndex = 0
      }
      return imageIndex;
    }
  }
};

export const getTrendingProducts = async () => {
  const { data, error } = (await supabaseClient
    .from("trending_products")
    .select("product_name, image_link, upc, trending_date")
    .eq("trending_date", YESTERDAY_DATE_STRING)) as DatabaseGetResponse;
  if (error) {
    console.log(error);
  }
  return data;
};
